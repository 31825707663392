var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcCascader from 'rc-cascader';
import omit from 'rc-util/lib/omit';
import RightOutlined from '@ant-design/icons/RightOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import devWarning from '../_util/devWarning';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import getIcons from '../select/utils/iconUtil';
import { getTransitionName } from '../_util/motion';
function highlightKeyword(str, lowerKeyword, prefixCls) {
    const cells = str
        .toLowerCase()
        .split(lowerKeyword)
        .reduce((list, cur, index) => (index === 0 ? [cur] : [...list, lowerKeyword, cur]), []);
    const fillCells = [];
    let start = 0;
    cells.forEach((cell, index) => {
        const end = start + cell.length;
        let originWorld = str.slice(start, end);
        start = end;
        if (index % 2 === 1) {
            originWorld = (
            // eslint-disable-next-line react/no-array-index-key
            <span className={`${prefixCls}-menu-item-keyword`} key={`seperator-${index}`}>
          {originWorld}
        </span>);
        }
        fillCells.push(originWorld);
    });
    return fillCells;
}
const defaultSearchRender = (inputValue, path, prefixCls, fieldNames) => {
    const optionList = [];
    // We do lower here to save perf
    const lower = inputValue.toLowerCase();
    path.forEach((node, index) => {
        if (index !== 0) {
            optionList.push(' / ');
        }
        let label = node[fieldNames.label];
        const type = typeof label;
        if (type === 'string' || type === 'number') {
            label = highlightKeyword(String(label), lower, prefixCls);
        }
        optionList.push(label);
    });
    return optionList;
};
const Cascader = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, size: customizeSize, className, multiple, bordered = true, transitionName, choiceTransitionName = '', popupClassName, dropdownClassName, expandIcon, showSearch, allowClear = true, notFoundContent, direction, getPopupContainer } = props, rest = __rest(props, ["prefixCls", "size", "className", "multiple", "bordered", "transitionName", "choiceTransitionName", "popupClassName", "dropdownClassName", "expandIcon", "showSearch", "allowClear", "notFoundContent", "direction", "getPopupContainer"]);
    const restProps = omit(rest, ['suffixIcon']);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction: rootDirection,
    // virtual,
    // dropdownMatchSelectWidth,
     } = React.useContext(ConfigContext);
    const mergedDirection = direction || rootDirection;
    const isRtl = mergedDirection === 'rtl';
    // =================== Warning =====================
    if (process.env.NODE_ENV !== 'production') {
        if (process.env.NODE_ENV !== 'production')
            devWarning(popupClassName === undefined, 'Cascader', '`popupClassName` is deprecated. Please use `dropdownClassName` instead.');
        devWarning(!multiple || !props.displayRender, 'Cascader', '`displayRender` not work on `multiple`. Please use `tagRender` instead.');
    }
    // =================== No Found ====================
    const mergedNotFoundContent = notFoundContent || renderEmpty('Cascader');
    // ==================== Prefix =====================
    const rootPrefixCls = getPrefixCls();
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const cascaderPrefixCls = getPrefixCls('cascader', customizePrefixCls);
    // =================== Dropdown ====================
    const mergedDropdownClassName = classNames(dropdownClassName || popupClassName, `${cascaderPrefixCls}-dropdown`, {
        [`${cascaderPrefixCls}-dropdown-rtl`]: mergedDirection === 'rtl',
    });
    // ==================== Search =====================
    const mergedShowSearch = React.useMemo(() => {
        if (!showSearch) {
            return showSearch;
        }
        let searchConfig = {
            render: defaultSearchRender,
        };
        if (typeof showSearch === 'object') {
            searchConfig = Object.assign(Object.assign({}, searchConfig), showSearch);
        }
        return searchConfig;
    }, [showSearch]);
    // ===================== Size ======================
    const size = React.useContext(SizeContext);
    const mergedSize = customizeSize || size;
    // ===================== Icon ======================
    let mergedExpandIcon = expandIcon;
    if (!expandIcon) {
        mergedExpandIcon = isRtl ? <LeftOutlined /> : <RightOutlined />;
    }
    const loadingIcon = (<span className={`${prefixCls}-menu-item-loading-icon`}>
      <LoadingOutlined spin/>
    </span>);
    // =================== Multiple ====================
    const checkable = React.useMemo(() => (multiple ? <span className={`${cascaderPrefixCls}-checkbox-inner`}/> : false), [multiple]);
    // ===================== Icons =====================
    const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple,
        prefixCls }));
    // ==================== Render =====================
    return (<RcCascader prefixCls={prefixCls} className={classNames(!customizePrefixCls && cascaderPrefixCls, {
            [`${prefixCls}-lg`]: mergedSize === 'large',
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-rtl`]: isRtl,
            [`${prefixCls}-borderless`]: !bordered,
        }, className)} {...restProps} direction={mergedDirection} notFoundContent={mergedNotFoundContent} allowClear={allowClear} showSearch={mergedShowSearch} expandIcon={mergedExpandIcon} inputIcon={suffixIcon} removeIcon={removeIcon} clearIcon={clearIcon} loadingIcon={loadingIcon} checkable={checkable} dropdownClassName={mergedDropdownClassName} dropdownPrefixCls={customizePrefixCls || cascaderPrefixCls} choiceTransitionName={getTransitionName(rootPrefixCls, '', choiceTransitionName)} transitionName={getTransitionName(rootPrefixCls, 'slide-up', transitionName)} getPopupContainer={getPopupContainer || getContextPopupContainer} ref={ref}/>);
});
Cascader.displayName = 'Cascader';
export default Cascader;
