var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import toArray from 'rc-util/lib/Children/toArray';
import copy from 'copy-to-clipboard';
import omit from 'rc-util/lib/omit';
import { composeRef } from 'rc-util/lib/ref';
import EditOutlined from '@ant-design/icons/EditOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import ResizeObserver from 'rc-resize-observer';
import useIsomorphicLayoutEffect from 'rc-util/lib/hooks/useLayoutEffect';
import { ConfigContext } from '../../config-provider';
import { useLocaleReceiver } from '../../locale-provider/LocaleReceiver';
import TransButton from '../../_util/transButton';
import { isStyleSupport } from '../../_util/styleChecker';
import Tooltip from '../../tooltip';
import Typography from '../Typography';
import Editable from '../Editable';
import useMergedConfig from '../hooks/useMergedConfig';
import useUpdatedEffect from '../hooks/useUpdatedEffect';
import Ellipsis from './Ellipsis';
import EllipsisTooltip from './EllipsisTooltip';
function wrapperDecorations({ mark, code, underline, delete: del, strong, keyboard, italic }, content) {
    let currentContent = content;
    function wrap(needed, tag) {
        if (!needed)
            return;
        currentContent = React.createElement(tag, {}, currentContent);
    }
    wrap(strong, 'strong');
    wrap(underline, 'u');
    wrap(del, 'del');
    wrap(code, 'code');
    wrap(mark, 'mark');
    wrap(keyboard, 'kbd');
    wrap(italic, 'i');
    return currentContent;
}
function getNode(dom, defaultNode, needDom) {
    if (dom === true || dom === undefined) {
        return defaultNode;
    }
    return dom || (needDom && defaultNode);
}
function toList(val) {
    return Array.isArray(val) ? val : [val];
}
const ELLIPSIS_STR = '...';
const Base = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, className, style, type, disabled, children, ellipsis, editable, copyable, component, title } = props, restProps = __rest(props, ["prefixCls", "className", "style", "type", "disabled", "children", "ellipsis", "editable", "copyable", "component", "title"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const textLocale = useLocaleReceiver('Text')[0]; // Force TS get this
    const typographyRef = React.useRef(null);
    const editIconRef = React.useRef(null);
    // ============================ MISC ============================
    const prefixCls = getPrefixCls('typography', customizePrefixCls);
    const textProps = omit(restProps, [
        'mark',
        'code',
        'delete',
        'underline',
        'strong',
        'keyboard',
        'italic',
    ]);
    // ========================== Editable ==========================
    const [enableEdit, editConfig] = useMergedConfig(editable);
    const [editing, setEditing] = useMergedState(false, {
        value: editConfig.editing,
    });
    const { triggerType = ['icon'] } = editConfig;
    const triggerEdit = (edit) => {
        var _a;
        if (edit) {
            (_a = editConfig.onStart) === null || _a === void 0 ? void 0 : _a.call(editConfig);
        }
        setEditing(edit);
    };
    // Focus edit icon when back
    useUpdatedEffect(() => {
        var _a;
        if (!editing) {
            (_a = editIconRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editing]);
    const onEditClick = (e) => {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        triggerEdit(true);
    };
    const onEditChange = (value) => {
        var _a;
        (_a = editConfig.onChange) === null || _a === void 0 ? void 0 : _a.call(editConfig, value);
        triggerEdit(false);
    };
    const onEditCancel = () => {
        var _a;
        (_a = editConfig.onCancel) === null || _a === void 0 ? void 0 : _a.call(editConfig);
        triggerEdit(false);
    };
    // ========================== Copyable ==========================
    const [enableCopy, copyConfig] = useMergedConfig(copyable);
    const [copied, setCopied] = React.useState(false);
    const copyIdRef = React.useRef();
    const cleanCopyId = () => {
        clearTimeout(copyIdRef.current);
    };
    const onCopyClick = (e) => {
        var _a;
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        copy(copyConfig.text || String(children) || '');
        setCopied(true);
        // Trigger tips update
        cleanCopyId();
        copyIdRef.current = setTimeout(() => {
            setCopied(false);
        }, 3000);
        (_a = copyConfig.onCopy) === null || _a === void 0 ? void 0 : _a.call(copyConfig);
    };
    React.useEffect(() => cleanCopyId, []);
    // ========================== Ellipsis ==========================
    const [isLineClampSupport, setIsLineClampSupport] = React.useState(false);
    const [isTextOverflowSupport, setIsTextOverflowSupport] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [isJsEllipsis, setIsJsEllipsis] = React.useState(false);
    const [isNativeEllipsis, setIsNativeEllipsis] = React.useState(false);
    const [enableEllipsis, ellipsisConfig] = useMergedConfig(ellipsis, {
        expandable: false,
    });
    const mergedEnableEllipsis = enableEllipsis && !expanded;
    // Shared prop to reduce bundle size
    const { rows = 1 } = ellipsisConfig;
    const needMeasureEllipsis = React.useMemo(() => 
    // Disable ellipsis
    !mergedEnableEllipsis ||
        // Provide suffix
        ellipsisConfig.suffix !== undefined ||
        ellipsisConfig.onEllipsis ||
        // Can't use css ellipsis since we need to provide the place for button
        ellipsisConfig.expandable ||
        enableEdit ||
        enableCopy, [mergedEnableEllipsis, ellipsisConfig, enableEdit, enableCopy]);
    useIsomorphicLayoutEffect(() => {
        if (enableEllipsis && !needMeasureEllipsis) {
            setIsLineClampSupport(isStyleSupport('webkitLineClamp'));
            setIsTextOverflowSupport(isStyleSupport('textOverflow'));
        }
    }, [needMeasureEllipsis, enableEllipsis]);
    const cssEllipsis = React.useMemo(() => {
        if (needMeasureEllipsis) {
            return false;
        }
        if (rows === 1) {
            return isTextOverflowSupport;
        }
        return isLineClampSupport;
    }, [needMeasureEllipsis, isTextOverflowSupport, isLineClampSupport]);
    const isMergedEllipsis = mergedEnableEllipsis && (cssEllipsis ? isNativeEllipsis : isJsEllipsis);
    const cssTextOverflow = mergedEnableEllipsis && rows === 1 && cssEllipsis;
    const cssLineClamp = mergedEnableEllipsis && rows > 1 && cssEllipsis;
    // >>>>> Expand
    const onExpandClick = e => {
        var _a;
        setExpanded(true);
        (_a = ellipsisConfig.onExpand) === null || _a === void 0 ? void 0 : _a.call(ellipsisConfig, e);
    };
    const [ellipsisWidth, setEllipsisWidth] = React.useState(0);
    const onResize = ({ offsetWidth }) => {
        setEllipsisWidth(offsetWidth);
    };
    // >>>>> JS Ellipsis
    const onJsEllipsis = (jsEllipsis) => {
        var _a;
        setIsJsEllipsis(jsEllipsis);
        // Trigger if changed
        if (isJsEllipsis !== jsEllipsis) {
            (_a = ellipsisConfig.onEllipsis) === null || _a === void 0 ? void 0 : _a.call(ellipsisConfig, jsEllipsis);
        }
    };
    // >>>>> Native ellipsis
    React.useEffect(() => {
        const textEle = typographyRef.current;
        if (enableEllipsis && cssEllipsis && textEle) {
            const currentEllipsis = cssLineClamp
                ? textEle.offsetHeight < textEle.scrollHeight
                : textEle.offsetWidth < textEle.scrollWidth;
            if (isNativeEllipsis !== currentEllipsis) {
                setIsNativeEllipsis(currentEllipsis);
            }
        }
    }, [enableEllipsis, cssEllipsis, children, cssLineClamp]);
    // ========================== Tooltip ===========================
    const tooltipTitle = ellipsisConfig.tooltip === true ? children : ellipsisConfig.tooltip;
    const topAriaLabel = React.useMemo(() => {
        const isValid = (val) => ['string', 'number'].includes(typeof val);
        if (!enableEllipsis || cssEllipsis) {
            return undefined;
        }
        if (isValid(children)) {
            return children;
        }
        if (isValid(title)) {
            return title;
        }
        if (isValid(tooltipTitle)) {
            return tooltipTitle;
        }
        return undefined;
    }, [enableEllipsis, cssEllipsis, title, tooltipTitle, isMergedEllipsis]);
    // =========================== Render ===========================
    // >>>>>>>>>>> Editing input
    if (editing) {
        return (<Editable value={typeof children === 'string' ? children : ''} onSave={onEditChange} onCancel={onEditCancel} onEnd={editConfig.onEnd} prefixCls={prefixCls} className={className} style={style} direction={direction} maxLength={editConfig.maxLength} autoSize={editConfig.autoSize} enterIcon={editConfig.enterIcon}/>);
    }
    // >>>>>>>>>>> Typography
    // Expand
    const renderExpand = () => {
        const { expandable, symbol } = ellipsisConfig;
        if (!expandable)
            return null;
        let expandContent;
        if (symbol) {
            expandContent = symbol;
        }
        else {
            expandContent = textLocale.expand;
        }
        return (<a key="expand" className={`${prefixCls}-expand`} onClick={onExpandClick} aria-label={textLocale.expand}>
        {expandContent}
      </a>);
    };
    // Edit
    const renderEdit = () => {
        if (!enableEdit)
            return;
        const { icon, tooltip } = editConfig;
        const editTitle = toArray(tooltip)[0] || textLocale.edit;
        const ariaLabel = typeof editTitle === 'string' ? editTitle : '';
        return triggerType.includes('icon') ? (<Tooltip key="edit" title={tooltip === false ? '' : editTitle}>
        <TransButton ref={editIconRef} className={`${prefixCls}-edit`} onClick={onEditClick} aria-label={ariaLabel}>
          {icon || <EditOutlined role="button"/>}
        </TransButton>
      </Tooltip>) : null;
    };
    // Copy
    const renderCopy = () => {
        if (!enableCopy)
            return;
        const { tooltips, icon } = copyConfig;
        const tooltipNodes = toList(tooltips);
        const iconNodes = toList(icon);
        const copyTitle = copied
            ? getNode(tooltipNodes[1], textLocale.copied)
            : getNode(tooltipNodes[0], textLocale.copy);
        const systemStr = copied ? textLocale.copied : textLocale.copy;
        const ariaLabel = typeof copyTitle === 'string' ? copyTitle : systemStr;
        return (<Tooltip key="copy" title={copyTitle}>
        <TransButton className={classNames(`${prefixCls}-copy`, copied && `${prefixCls}-copy-success`)} onClick={onCopyClick} aria-label={ariaLabel}>
          {copied
                ? getNode(iconNodes[1], <CheckOutlined />, true)
                : getNode(iconNodes[0], <CopyOutlined />, true)}
        </TransButton>
      </Tooltip>);
    };
    const renderOperations = (renderExpanded) => [
        renderExpanded && renderExpand(),
        renderEdit(),
        renderCopy(),
    ];
    const renderEllipsis = (needEllipsis) => [
        needEllipsis && (<span aria-hidden key="ellipsis">
        {ELLIPSIS_STR}
      </span>),
        ellipsisConfig.suffix,
        renderOperations(needEllipsis),
    ];
    return (<ResizeObserver onResize={onResize} disabled={!mergedEnableEllipsis || cssEllipsis}>
      {resizeRef => (<EllipsisTooltip title={tooltipTitle} enabledEllipsis={mergedEnableEllipsis} isEllipsis={isMergedEllipsis}>
          <Typography className={classNames({
                [`${prefixCls}-${type}`]: type,
                [`${prefixCls}-disabled`]: disabled,
                [`${prefixCls}-ellipsis`]: enableEllipsis,
                [`${prefixCls}-single-line`]: mergedEnableEllipsis && rows === 1,
                [`${prefixCls}-ellipsis-single-line`]: cssTextOverflow,
                [`${prefixCls}-ellipsis-multiple-line`]: cssLineClamp,
            }, className)} style={Object.assign(Object.assign({}, style), { WebkitLineClamp: cssLineClamp ? rows : undefined })} component={component} ref={composeRef(resizeRef, typographyRef, ref)} direction={direction} onClick={triggerType.includes('text') ? onEditClick : null} aria-label={topAriaLabel} title={title} {...textProps}>
            <Ellipsis enabledMeasure={mergedEnableEllipsis && !cssEllipsis} text={children} rows={rows} width={ellipsisWidth} onEllipsis={onJsEllipsis}>
              {(node, needEllipsis) => {
                let renderNode = node;
                if (node.length && needEllipsis && topAriaLabel) {
                    renderNode = (<span key="show-content" aria-hidden>
                      {renderNode}
                    </span>);
                }
                const wrappedContext = wrapperDecorations(props, <>
                    {renderNode}
                    {renderEllipsis(needEllipsis)}
                  </>);
                return wrappedContext;
            }}
            </Ellipsis>
          </Typography>
        </EllipsisTooltip>)}
    </ResizeObserver>);
});
export default Base;
